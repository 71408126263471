import { PageContainer } from "../../components/PageContainer";
import { PageLayout } from "../../components/PageLayout";
import { PageParticleSectionJumbotron } from "../../components/PageParticleSectionJumbotron";
import { PageSection } from "../../components/PageSection";
import { SiteSEO } from "../../components/SiteSEO";
import { graphql, PageProps } from "gatsby";
import React from "react";

const SimpleTextView = ({ data }: PageProps<Queries.SimpleTextRenderQuery>) => {
  const title = data?.markdownRemark?.frontmatter?.title;
  const html = data?.markdownRemark?.html;

  return (
    <PageLayout transparentHeader>
      <SiteSEO title={title || ""} />
      <PageParticleSectionJumbotron>{title}</PageParticleSectionJumbotron>
      <PageSection>
        <PageContainer>
          <div
            className="prose py-16"
            dangerouslySetInnerHTML={{ __html: html || "" }}
          />
        </PageContainer>
      </PageSection>
    </PageLayout>
  );
};

export default SimpleTextView;

export const query = graphql`
  query SimpleTextRender($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
